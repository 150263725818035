<template>
  <v-container class="grey lighten-5">
    <div class="d-flex align-center flex-wrap justify-space-between">
      <div>
        <v-tabs v-model="activeTab" center-active show-arrows>
          <v-tab
            v-for="item in navs"
            :key="item"
            @click="$router.push({
              name: item
            })">
            {{ item }}
          </v-tab>
        </v-tabs>
      </div>
      <div>
        <v-btn
          v-if="$route.name === 'Package'"
          depressed
          class="text-capitalize"
          color="#00D79E"
          style="color: #FFFFFF"
          @click="handleEdit">
          Edit Price
        </v-btn>
      </div>
    </div>

    <router-view></router-view>

    <v-dialog v-model="showDialog" max-width="50%" @click:outside="handleClose">
      <v-card v-show="!showEdit">
        <v-toolbar color="elevation-0" class="d-flex" >
          <v-toolbar-title >
              <span class="ml-4 font-weight-bold font-22" >Edit Prices</span>
          </v-toolbar-title>
          <v-toolbar-items style="position: fixed; right: 10px;">
              <v-btn icon @click="handleClose">
                  <v-icon color="#000000">mdi-close</v-icon>
              </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="px-10 mt-8 pb-4">
            <div v-for="(item, idx) in dataCurrency" :key="idx" class="px-6 py-2 mb-3" style="box-shadow: 0px 0px 24px #00000014; border-radius: 8px;">
                <div class="d-flex pt-2 pointer" @click="handleShow(item)">
                    <div class="full-width">{{ item.name }} ( {{ item.id }} )</div>
                    <div class="mt-n2">
                        <v-btn icon @click="handleShow(item)">
                            <v-icon >{{ tempCurr[idx] ? 'mdi-chevron-down' : 'mdi-chevron-right'}}</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
      </v-card>
      <v-card v-show="showEdit">
        <v-toolbar color="elevation-0" class="d-flex" >
          <v-toolbar-title >
              <span class="ml-4 font-weight-bold font-22" >{{ tempPriceList.name }} ( {{ tempPriceList.id }} )</span>
          </v-toolbar-title>
          <v-toolbar-items style="position: fixed; right: 10px;">
              <v-btn icon @click="handleClose">
                  <v-icon color="#000000">mdi-close</v-icon>
              </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="px-10 mt-8 pb-4">
          <div v-for="item in tempPriceList.prices" :key="item.id" class="px-6 py-2 mb-3" style="box-shadow: 0px 0px 24px #00000014; border-radius: 8px;">
            <div class="d-flex pt-2">
              <div class="full-width">{{ item.subscription_title }}</div>
            </div>
            <div>
              <hr class="mt-4" style="border: 1px solid #E0E0E0">
              <div class="d-flex mt-4">
                <div class="full-width pr-2">
                  <span class="font-14">Monthly Price</span>
                  <v-text-field v-model="item.price_monthly" outlined dense height="40px" style="min-height: unset !important"></v-text-field>
                </div>
                <div class="full-width pl-2">
                  <span class="font-14">Yearly Price</span>
                  <v-text-field v-model="item.price_annually" outlined dense height="40px" style="min-height: unset !important"></v-text-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex pt-4 px-4" style="box-shadow: 0px -5px 5px #F5F5F5; height: 80px">
          <div class="text-right full-width">
            <v-btn class="text-capitalize" color="#00D79E" depressed style="color: #FFFFFF" @click="updateCurrency">Save Setting</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { fetchCurrencyList, fetchCurrencyDetail, updateCurrencyDetail as updateCurrency } from '@/api/zenwelbiz'
export default {
  data () {
    return {
      activeTab: null,
      navs: [
        'Package',
        'Business',
        'Subscription',
        'Marketplace',
        'Biz Deals'
      ],
      showDialog: false,
      showEdit: false,
      dataCurrency: [],
      tempCurr: [false],
      tempPriceList: []
    }
  },

  watch: {
    '$route.name' (name) {
      this.setActiveTab(name)
    }
  },

  mounted () {
    this.fetchCurrencyList()
    const activeTab = this.$route.name
    this.setActiveTab(activeTab)
  },

  methods: {
    setActiveTab (name) {
      this.activeTab = this.navs.indexOf(name)
    },

    fetchCurrencyList () {
      fetchCurrencyList().then((response) => {
        this.dataCurrency = response.data.data
      }).catch(() => {
        // console.log('rsp', error)
      })
    },

    handleEdit () {
      this.dataCurrency.map(i => {
        this.tempCurr.push(false)
      })
      this.showDialog = true
    },

    handleShow (data) {
      fetchCurrencyDetail(data.id).then((response) => {
        this.tempPriceList = response.data.data
        this.showEdit = true
      }).catch(() => {
        // console.log('rsp', error)
      })
    },

    updateCurrency () {
      let data = {
        id: this.tempPriceList.id,
        prices: []
      }
      this.tempPriceList.prices.map(i => {
        data.prices.push({
          subscription_id: i.id,
          price_annually: i.price_annually,
          price_monthly: i.price_monthly
        })
      })
      updateCurrency(data).then((response) => {
        this.showEdit = false
        this.showDialog = false
        this.fetchCurrencyList()
      }).catch(() => {
        // console.log('rsp', error)
      })
    },

    handleClose () {
      this.showDialog = false
      this.showEdit = false
      this.tempPriceList = []
    }
  }
}
</script>
